/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

body {
    background-image: url(/assets/images/backgrounds/wood.jpg);
}

h1 {
    font-family: "Bebas Neue", arial, sans-serif;
    color: #30C2B1;
    font-size: 30px;
    margin-top: 25px;
    line-height: normal;
    &:after {
        content:"";
        width: 50px;
        height: 2px;
        background: #30C2B1;
        display: block;
        margin: 0px auto 10px;
    }
}

h2 {
    font-family: "Bebas Neue", arial, sans-serif;
    color: #30C2B1;
    font-size: 25px;
    line-height: 1;
    span {
        color: #184540;
    }
}

h3 {
    font-family: "Bebas Neue", arial, sans-serif;
    color: #666666;
    font-size: 18px;
}
a {
    color: #30C2B1;
    outline: none;
    cursor: pointer;
}

p, .wrapper {
    color: #666666;
}

.btn {
    display: inline-block;
    border-radius: 100px;
    padding: 7px 13px;
    color: #fff;
    font-weight: bold;
    transition: .3s;
    white-space: nowrap;
    &--transparent {
        background: none;
        border: 2px solid #30C2B1;
        &:hover {
            background: #4B5B60;
        }
    }
    &--turq {
        background: #30C2B1;
        border: 2px solid #30C2B1;
        &:hover {
            color: #000;
            background: #FFEE00;
            border-color: #FFEE00;
        }
    }
}

.embedresize {
    margin: auto;
    div {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: none;
        }
    }
}

.image-text {
    display: flex;
    align-items: center;
    gap: 25px;
    &-pic {
      max-width: 400px;
    }
    &-presentation {
      text-align: left;
      h1{
        margin-top: 0;
        &:after {
          display: none;
        }
      }
      h2 {
        color: #666;
      }
    }
  }

.mobile-header, .mobile-header.h-16  {
    background: #021425 !important;
    height: 56px !important;
    padding: 0 10px 0 0 !important;
    display: none !important;
    img {
        max-width: 150px;
        margin-left: 15px;
    }
    .mat-icon svg {
        color: #30C2B1;
    }
}

.wrapper {
    width: 100%;
    &__content {
        position: relative;
        z-index: 5;
        width: 93%;
        margin: auto;
        .content-bloc {
            background: #fff;
            border-radius: 15px;
            text-align: center;
            box-shadow: 0 0 15px rgba(0,0,0,0.3);
            overflow: hidden;
            max-width: 800px;
            margin: 40px auto;
            iframe {
                margin-bottom: -5px;
            }
            &__header {
                background: #021425;
                width: 100%;
                padding: 30px 0 5px 0;
                position: relative;
                min-height: 120px;
                display: flex;
                img {
                    margin: auto;
                    max-height: 55px;
                    padding: 0 20px;
                }
                svg {
                    margin: auto;
                    max-height: 80px;
                }
                &:after {
                    content: "";
                    height: 0;
                    border: 0 solid transparent;
                    border-left-width: 0px;
                    border-right-width: 1000px;
                    border-top: 40px solid #021425;
                    position: absolute;
                    bottom: -40px;
                    width: 100%;
                    left: 0;
                }
            }
            &__content {
                padding: 40px;
                position: relative;
            }
        }
    }
    &__background {
        padding: 20px;
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        z-index: -1;
        top: 0;
        img {
            margin: auto;
            position: absolute;
            z-index: 0;
            &.top-left {
                z-index: 0;
                left: 0;
                top: 0;
            }
            &.top-right {
                z-index: 1;
                right: 0;
                top: 0;
            }
            &.bottom-right {
                z-index: 0;
                right: 0;
                bottom: 0;
            }
            &.bottom-left {
                z-index: 0;
                left: 0;
                bottom: 0;
            }
        }
    }
}

// Content component
content {

    &.inner-scroll {
        flex: 1 1 0%;
        min-height: 0;

        > *:not(router-outlet) {
            flex: 1 1 0%;
        }
    }
}

.band_testimony {
    display: flex;
    justify-content: center;
    margin: 30px 0 50px;
    &__content {
        text-align: left;
        margin: auto 10px;
        width: 50%;
        h3, h2 {
            margin: 0;
        }
        &__author {
            display: flex;
            &--name {
                font-size: 14px;
                margin: auto 10px;
                &--title {
                    font-weight: bold;
                    margin: 0;
                }
                &--name {
                    font-style: italic;
                    margin: 0;
                }
            }
        }
    }
}

.content-bloc {
    &--speeddrawing {
        iframe {
            padding: 10px 25px 25px 25px;
            margin-bottom: 0;
        }
    }
}

.story {
    text-align: left;
    max-width: 500px;
    margin: 30px auto;
}

.gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
        max-width: 330px;
        width: 100%;
        height: 100%;
        margin: 10px;
        border-radius: 6px;
        box-shadow: 0 0 20px rgba(0,0,0,0.3);
        border: 3px solid #fff;
        transition: .3s;
        cursor: pointer;
        &:hover {
            border-color: #30C2B1;
            transform: scale(1.05);
        }
    }
} 

.carousel {
    &-backdrop {
        opacity: .8 !important;
    }
    &-cell {
        img {
            object-fit: contain !important;
            width: 95vw !important;
            height: 95vh !important;
        }
    } 
    &-arrow {
        background-color: #46B8AC !important;
        &-disabled {
            display: none;
        }
    }
}

.footer-signature {
    background: #020A14;
    font-size: 11px;
    text-align: center;
    padding: 10px;
    p {
        color: #fff;
    }
}

@media only screen and (max-width: 959px) {
    .mobile-header, .mobile-header.h-16  {
        display: flex !important;
    }
    .wrapper__background {
        display: none;
    }
}


@media only screen and (max-width: 600px) {
    vertical-layout-1 #main > .container > .container > .container .wrapper__content .content-bloc {
        margin: 25px auto;
    }
    .gallery {
        img {
            margin: 5px;
        }
    }
    .image-text {
        flex-direction: column;
        &-presentation {
          text-align: center;
        }
      }
    vertical-layout-1 #main > .container > .container > .container .wrapper__content .content-bloc__content {
        padding: 40px 20px;
    }
    vertical-layout-1 #main > .container > .container > .container .wrapper__background {
        display: none;
    }
    .band_testimony {
        flex-direction: column;
        margin: 15px 0px;
        &__content {
            text-align: center;
            margin: 15px 10px 0;
            width: 100%;
            &__author {
                justify-content: center;
                &--name {
                    text-align: left;
                }
            }
        }
    }
}


.spinner-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 500px;
    left: 0;
    top: 0;
    .spinner {
        overflow: hidden;
        left: 50%;
        margin-left: -50px;
        animation: outer-rotate 2.91667s linear infinite;
        margin: auto;
        .inner {
            width: 100px;
            height: 100px;
            position: relative;
            animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            .gap {
                position: absolute;
                left: 49px;
                right: 49px;
                top: 0;
                bottom: 0;
                border-top: 10px solid;
                box-sizing: border-box;
            }
        }
    }
}

.spinner-wrapper .spinner .inner .left,
.spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
}

.spinner-wrapper .spinner .inner .left .half-circle,
.spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid #30C2B1;
    border-bottom-color: transparent;
    border-radius: 50%;
}

.spinner-wrapper .spinner .inner .left {
    left: 0;
}

.spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.spinner-wrapper .spinner .inner .right {
    right: 0;
}

.spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

@keyframes outer-rotate {
    0% {
        transform: rotate(0deg) scale(0.5);
    }
    100% {
        transform: rotate(360deg) scale(0.5);
    }
}

@keyframes left-wobble {
    0%, 100% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
}

@keyframes right-wobble {
    0%, 100% {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
}

@keyframes sporadic-rotate {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    100% {
        transform: rotate(1080deg);
    }
}